<template>
  <div>
    <h2>The Office</h2>
    <v-row>
      <template v-for="(brew, i) in breweries" >
        <v-col
          :key="i"
          v-if="brew.location === 'office'"
          class="d-flex child-flex"
          cols="4"
        >

          <router-link :to="{ name: 'Brew', params: { id: brew.objectId }}">          
          <img
            :src="brew.checked === false ? brew.greyLogoURL : brew.logoURL"
            aspect-ratio="1"
            style="border-radius:5px;background:black;width:80px;height:80px;"
            :href="brew.siteURL"
          /></router-link>
        </v-col>
      </template>
    </v-row>
<v-divider></v-divider>
<v-divider></v-divider>
<h2>Urban Bottle</h2>
    <v-row>
      <template v-for="(brew, i) in breweries" >
        <v-col
          :key="i"
          v-if="brew.location === 'ub'"
          class="d-flex child-flex"
          cols="4"
        >
          <router-link :to="{ name: 'Brew', params: { id: brew.objectId }}">          
          <img
            :src="brew.checked === false ? brew.greyLogoURL : brew.logoURL"
            aspect-ratio="1"
            style="border-radius:5px;background:black;width:80px;height:80px;"
            :href="brew.siteURL"
          /></router-link>
        </v-col>
      </template>
    </v-row>
<v-divider></v-divider>
<v-divider></v-divider>
<h2>Racca's</h2>
    <v-row>
      <template v-for="(brew, i) in breweries" >
        <v-col
          :key="i"
          v-if="brew.location === 'raccas'"
          class="d-flex child-flex"
          cols="4"
        >
          <router-link :to="{ name: 'Brew', params: { id: brew.objectId }}">          
          <img
            :src="brew.checked === false ? brew.greyLogoURL : brew.logoURL"
            aspect-ratio="1"
            style="border-radius:5px;background:black;width:80px;height:80px;"
            :href="brew.siteURL"
          /></router-link>
        </v-col>
      </template>
    </v-row>
<v-divider></v-divider>
<v-divider></v-divider>
<h2>Gaslight Social</h2>
    <v-row>
      <template v-for="(brew, i) in breweries" >
        <v-col
          :key="i"
          v-if="brew.location === 'gaslight'"
          class="d-flex child-flex"
          cols="4"
        >
          <router-link :to="{ name: 'Brew', params: { id: brew.objectId }}">          
          <img
            :src="brew.checked === false ? brew.greyLogoURL : brew.logoURL"
            aspect-ratio="1"
            style="border-radius:5px;background:black;width:80px;height:80px;"
            :href="brew.siteURL"
          /></router-link>
        </v-col>
      </template>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
// import { results } from '@/data.js'
export default {
  // data() {
  //   return {
  //     breweries: results.name
  //   }
  // },

  computed: {
    ...mapGetters("brew", ["breweries"])
  }
}
</script>

<style>

</style>